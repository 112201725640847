import React from 'react'

import Button from '../../Button'
import img_ampersand from '../../../assets/ampersand.png'
import img_portier from '../../../assets/images/index_portier.jpg'

import styles from './About.module.scss'

const About = () => (
  <div className={styles.about}>
    <div className={styles.introWrapper}>
      <div className={styles.intro}>
        <h2 className="t-heading-sub">Over ons</h2>
        <span className="t-heading-l">W&R Beveiliging</span>

        <p>
          W&R beveiliging is opgericht door Sander van der Walle en Willem
          Rutten. Twee experts uit de beveiligingsbranche. Zij introduceerden
          een unieke beveiligingsaanpak. Namelijk Proactieve Gastvrije
          beveiliging.
        </p>

        <p>
          Onze medewerkers zijn geen imponerende portiers. Het zijn gastvrije
          beveiligers. Zij zijn geselecteerd en getraind op hun communicatieve
          en klantvriendelijke vaardigheden. En werken volgens het bekende
          spreekwoord: beter voorkomen, dan genezen.
        </p>

        <Button type="link" to="/over-ons/" color="confirm">
          Meer info
        </Button>

        <img src={img_ampersand} className={styles.ampersand} alt="ampersand" />
      </div>

      <div className={styles.image}>
        <img src={img_portier} alt="beveiligingsbedrijf" />
      </div>
    </div>
  </div>
)

export default About
