import React from 'react'
import ReactDOM from 'react-dom'

import styles from './Modal.module.scss'

class Modal extends React.Component {
  state = {
    open: false,
  }

  componentDidMount = () => {
    if (document && !document.getElementById('modal')) {
      const domModal = document.createElement('div')
      domModal.id = 'modal'
      document.body.insertBefore(domModal, document.body.childNodes[0])
    }

    this._isMounted = true
    window.addEventListener('keydown', this._handleKeyDown)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this._handleKeyDown)
    this._isMounted = false
  }

  _toggleModal = (newState) => {
    if (this._isMounted) {
      this.setState({ open: newState || !this.state.open })
    }
  }

  _handleBgClick = (e) => {
    if (e.target === e.currentTarget) {
      this._toggleModal()
    }
  }

  _handleKeyDown = (e) => {
    if (this.state.open && e.key === 'Escape') {
      this._toggleModal()
    }
  }

  render() {
    return (
      <>
        {React.cloneElement(this.props.children[0], {
          onClick: this._toggleModal,
        })}

        {this.state.open &&
          ReactDOM.createPortal(
            <div
              className={styles.overlay}
              onClick={(e) => this._handleBgClick(e)}
              onKeyDown={(e) => this._handleKeyDown(e)}
              role="button"
              tabIndex={-1}
            >
              <button
                onClick={() => this._toggleModal()}
                className={styles.closeButton}
              >
                ×
              </button>

              <div className={styles.content}>
                {React.cloneElement(this.props.children[1], {
                  onClick: this._toggleModal,
                })}
              </div>
            </div>,
            document.getElementById('modal')
          )}
      </>
    )
  }
}

export default Modal
