import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Button from '../../Button'
import GlobalContext from '../../../utils/globalContext'
import Icon from '../../Icon'
import Modal from '../../../components/Modal'
import Service from './Service'

import styles from './Hero.module.scss'

const query = graphql`
  query {
    image: file(relativePath: { eq: "assets/images/dienst_retail.jpg" }) {
      childImageSharp {
        fluid(
          maxHeight: 800
          maxWidth: 1600
          cropFocus: NORTHWEST
          quality: 80
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Hero = () => (
  <div className={styles.hero}>
    <GlobalContext.Consumer>
      {({ i18n }) => (
        <div className={styles.serviceWrapper}>
          <div className={styles.services}>
            {i18n.index.hero.slice(0, 7).map((item) => (
              <Service
                key={item.title}
                title={item.title}
                icon={item.icon}
                description={item.description}
                ctaText={item.ctaText}
                ctaPath={item.ctaPath}
                ctaAltText={item.ctaAltText}
                ctaAltPath={item.ctaAltPath}
                special={item.special}
              />
            ))}

            <Modal>
              <button className={styles.ctaVideo}>
                <h3 className={styles.ctaTitle}>
                  Bekijk hier onze bedrijfsfilm
                </h3>

                <Icon name="chevronRightLine" size={40} />
              </button>

              <div className={styles.ctaVideoWrapper}>
                <iframe
                  title="Bedrijfsfilm"
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/01UklTpy0nI?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </Modal>

            <div className={styles.ctaContact}>
              <h3 className={styles.ctaTitle}>Contact</h3>

              <div className={styles.clientContact}>
                <a
                  href={`tel:${i18n.misc.phoneHtml}`}
                  className={styles.clientPhone}
                >
                  {i18n.misc.phoneHuman}
                </a>

                <a
                  href={`mailto:${i18n.misc.email}`}
                  className={styles.clientEmail}
                >
                  {i18n.misc.email}
                </a>
              </div>

              <Button type="link" to="/contact/" color="confirm" inline>
                Afspraak <br />
                maken
              </Button>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>

    <StaticQuery
      query={query}
      render={({ image }) => (
        <Img
          fluid={image.childImageSharp.fluid}
          className={styles.image}
          fadeIn={false}
          imgStyle={{ objectPosition: '20% center' }}
        />
      )}
    />
  </div>
)

export default Hero
