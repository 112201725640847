import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import About from '../components/PageHome/About'
import ContactForm from '../components/ContactForm'
import Hero from '../components/PageHome/Hero'
import Items from '../components/Items'
import Root from '../layouts/Root'
import Testimonials from '../components/Testimonials'
import Values from '../components/PageHome/Values'

import config from '../config'

const Index = ({ data }) => (
  <Root>
    {/* prettier-ignore */}
    <Helmet>
      <title>{`${config.title} - Beveiligingsbedrijf uit Uden`}</title>
      <meta property="og:title" content={`${config.title} - Beveiligingsbedrijf uit Uden`} />
      <meta name="description" content="Beveiligingsbedrijf uit Uden. Beveiliging van bedrijven, evenementen & horeca. Landelijk inzetbaar. 3 keurmerken, 3 ISO certificaten en OHSAS 18001." />
      <meta property="og:description" content="Beveiligingsbedrijf uit Uden. Beveiliging van bedrijven, evenementen & horeca. Landelijk inzetbaar. 3 keurmerken, 3 ISO certificaten en OHSAS 18001." />
    </Helmet>

    <header>
      <Hero />
    </header>

    <main>
      <About />
      <Values />
      <Testimonials />
      <Items
        title="Nieuws"
        data={data.nieuws}
        allItemsPath="/nieuws"
        allItemsText="Bekijk alle nieuwsberichten"
      />
      <ContactForm />
    </main>
  </Root>
)

export const query = graphql`
  query {
    nieuws: allMarkdownRemark(
      limit: 3
      filter: { fields: { slug: { regex: "/^/nieuws/.+/" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            cover {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default Index
