import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Icon from '../../Icon'
import Button from '../../Button'

import styles from './Service.module.scss'

const Service = ({
  ctaAltPath,
  ctaAltText,
  ctaPath,
  ctaText,
  description,
  icon,
  special,
  title,
}) => {
  if (special) {
    return (
      <div className={[styles.service, styles.serviceSpecial].join(' ')}>
        <h3 className={styles.title}>
          <Icon name={icon} size={32} valign="text-bottom" />
          <span>{title}</span>
        </h3>
        <p className={styles.description}>{description}</p>
        <div>
          <Button type="link" to={ctaPath} color="white" size="small" inline>
            {ctaText}
          </Button>

          <Link to={ctaAltPath} className={styles.ctaAlt}>
            {ctaAltText}
          </Link>
        </div>
      </div>
    )
  }

  return (
    <Link to={ctaPath} className={styles.service}>
      <Icon name={icon} size={32} />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      <div>
        <Button color="confirm" size="small">
          {ctaText}
        </Button>
      </div>
    </Link>
  )
}

Service.propTypes = {
  ctaAltPath: PropTypes.string,
  ctaAltText: PropTypes.string,
  ctaPath: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  special: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default Service
