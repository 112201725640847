import React from 'react'

import styles from './Values.module.scss'

const Values = () => (
  <div className={styles.values}>
    <div className={styles.value}>
      <h3 className="t-heading-m">Lid van VVEM en CBO</h3>
      <p>
        Wij zijn lid van de Nederlandse Veiligheidsbranche en VVEM (vereniging
        van evenementen makers). En zijn tevens lid van het CBO. Dit is een
        samenwerkingsverband van Nederlandse beveiligingsbedrijven. Hierdoor
        kunnen wij bij calamiteiten of incidenten razendsnel opschalen. En
        bieden wij landelijke dekking op het gebied van alarmopvolging en
        mobiele surveillance
      </p>
    </div>

    <div className={styles.value}>
      <h3 className="t-heading-m">Keurmerken en certificering</h3>
      <p>
        Wij beschikken over een ISO 9001, ISO 14001, ISO 26000 en OHSAS 18001
        certificaat. En 3 keurmerken van de Nederlandse Veiligheidsbranche:
        Beveiliging, Evenementenbeveiliging en Horecabeveiliging.
      </p>
    </div>

    <div className={styles.value}>
      <h3 className="t-heading-m">Gastvrije beveiligers</h3>
      <p>
        Onze medewerkers zijn een visitekaartje van de opdrachtgever. Daarom
        zijn onze medewerkers stuk voor stuk gastheren en gastdames met een
        representatief voorkomen. Al onze medewerkers volgen jaarlijks de cursus
        gastvrij beveiligen.
      </p>
    </div>

    <div className={styles.value}>
      <h3 className="t-heading-m">Proactieve beveiliging</h3>
      <p>
        Voorkomen is beter dan genezen. Onze medewerkers signaleren afwijkende
        situaties in een vroeg stadium. Door proactief op te treden willen we
        incidenten voorkomen. Om een prettige sfeer te behouden.
      </p>
    </div>
  </div>
)

export default Values
